<template>
  <v-virtual-scroll :items="adminUsers" :item-height="50" height="306">
    <template v-slot:default="{ index, item }">
      <v-list-item>
        <v-list-item-avatar class="my-auto">
          <v-avatar color="grey">
            <v-icon color="white">mdi-domain</v-icon>
          </v-avatar>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-content>
          <v-list-item-title >{{ item.email }}</v-list-item-title>
        </v-list-item-content>

        <!-- <v-list-item-action>
          <v-btn depressed small @click="goToTenantDetails(item)">
            {{ $t("generals.viewDetails") }}

            <v-icon color="orange darken-4" right> mdi-open-in-new </v-icon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action>
          <v-btn icon @click="confirmDeleteTenant(item)">
            <v-icon color="error">mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action> -->
      </v-list-item>
      <v-divider v-if="index < adminUsers.length - 1" :key="index"></v-divider>
    </template>
  </v-virtual-scroll>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    colors: ["#2196F3"],
    names: ["Oliver", "Jake", "Noah"],
    surnames: ["Smith", "Anderson", "Clark"],
  }),
  computed: {
    ...mapGetters(["adminUsers"]),
  },
  methods: {
    ...mapActions([""]),
    genRandomIndex(length) {
      return Math.ceil(Math.random() * (length - 1));
    },
    goToTenantDetails(item = null) {
      if (!item) return;
      const self = this;
      const tenantId = item.id;
      if(item.is_supplier){
        self.$router.push({ path: `/supplier/manage/${tenantId}` });
      } else{
        self.$router.push({ path: `/tenant/manage/${tenantId}` });
      }
      
    },
    confirmDeleteTenant(item = null) {
      if (!item) return;
      const self = this;
      self.$swal
        .fire({
          title: "Do you really want to delete this tenant?",
          text: "All information will be lost",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `Delete`,
          denyButtonText: `Cancel`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            self.value = true;
            self.$emit("input", self.value);
            self.deleteTenant(item).finally(() => {
              self.value = false;
              self.$emit("input", self.value);
            });
          }
        });
    },
  },
};
</script>